import { Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AddDepartementComponent } from '../../departement/component/add-departement/add-departement.component';
import { AddSkillComponent } from '../../skill/component/add-skill/add-skill.component';
import { WorkStructure } from '../model/company-settings.model';
import { CompanySettingsService } from '../services/company-settings.service';

@Component({
  selector: 'app-settings-page',
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.css'],
})
export class SettingsPageComponent {
  DepartementId: number = null;
  DepartementName: string = null;
  structure: number = WorkStructure.Departments;
  workStructureEnum = WorkStructure;

  constructor(
    private dialog: MatDialog,
    public companySettingsService: CompanySettingsService
  ) {
    this.structure = this.companySettingsService.CompanySettings.WorkStructure;
  }

  ngOnInit(): void {}

  addDepartement(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.maxHeight = '90vh';
    dialogConfig.maxWidth = '90vw';
    dialogConfig.panelClass = 'custom-dialog-container';
    this.dialog.open(AddDepartementComponent, dialogConfig);
  }

  addSkill(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.maxHeight = '90vh';
    dialogConfig.maxWidth = '90vw';
    dialogConfig.panelClass = 'custom-dialog-container';
    this.dialog.open(AddSkillComponent, dialogConfig);
  }

  setDepartementId(id: number): void {
    this.DepartementId = id;
  }

  setDepartementName(name: string): void {
    this.DepartementName = name;
  }
}
