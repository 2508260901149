import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable } from 'rxjs';
import { ImageService } from 'src/app/core/services/upload/Image.service';
import {
  ApiEndPoints,
  ApiMethod,
  EventType,
  SortShiftBy,
  WorkShiftStatus,
} from '../../../core/services/const';
import { HttpService } from '../../../core/services/http/http.service';
import { AuthService } from '../../auth/services/auth/auth.service';
import { DepartementService } from '../../departement/services/departement.service';
import { CompanySettingsService } from '../../settings/services/company-settings.service';
import { CalendarWeek } from '../model/CalendarWeek';
import { TimeEvent } from '../model/time-event.model';

dayjs.extend(isSameOrAfter);

@Injectable({
  providedIn: 'root',
})
export class CalendarService {
  constructor(
    private httpClient: HttpService,
    private departement: DepartementService,
    public authService: AuthService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private companySettings: CompanySettingsService,
    private imageService: ImageService
  ) {}

  private calenderWeekListSource: BehaviorSubject<any> = new BehaviorSubject(
    []
  );
  calenderWeekList = this.calenderWeekListSource.asObservable();

  private employeeEventListSource: BehaviorSubject<any> = new BehaviorSubject(
    []
  );
  employeeEventList = this.employeeEventListSource.asObservable();

  private notAcceptedEventListSource: BehaviorSubject<any> =
    new BehaviorSubject([]);
  notAcceptedEventList = this.notAcceptedEventListSource.asObservable();

  private employeeTimelineEventListSource: BehaviorSubject<any> =
    new BehaviorSubject(null);
  employeeTimelineEventList =
    this.employeeTimelineEventListSource.asObservable();

  private eventsAndScheduleRequirementStatusListSource: BehaviorSubject<any> =
    new BehaviorSubject(null);
  eventsAndScheduleRequirementStatusList =
    this.eventsAndScheduleRequirementStatusListSource.asObservable();

  private planTemplateListSource: BehaviorSubject<any> = new BehaviorSubject(
    []
  );
  planTemplateList = this.planTemplateListSource.asObservable();

  private workShiftDetailsSource: BehaviorSubject<any> = new BehaviorSubject(
    []
  );
  workShiftDetails = this.workShiftDetailsSource.asObservable();

  private currentWeekStartSource: BehaviorSubject<any> = new BehaviorSubject(
    []
  );
  public selectedDepartementList = [];

  currentFilter: SortShiftBy = SortShiftBy.ByAvailableTime;
  currentWeekStart = this.currentWeekStartSource.asObservable();
  public currentWeek = null;

  public calendarCurrentStart = null;

  public calendarCurrentStartFromHorizontalCharts = null;
  addEvent(event: TimeEvent, isTimeline = false, oneDay = false): HttpService {
    event.StarTime = new Date(event.StarTime);
    if (event.EndTime) {
      event.EndTime = new Date(event.EndTime);
    }
    return this.httpClient
      .requestCall(ApiEndPoints.AddEvent, ApiMethod.POST, event)
      .subscribe((res: number) => {
        if (res === -1) {
          this.toastr.error(
            this.translate.instant(
              'THE EMPLOYEE HAS VACATION OR IS SICK DURING THIS PERIOD'
            ),
            this.translate.instant('ENTRY NOT POSSIBLE')
          );
        }

        if (res === -2) {
          this.toastr.error(
            this.translate.instant(
              'THE SELECTED USER IS CURRENTLY NOT EMPLOYED BY THIS COMPANY OR THEIR ACCOUNT HAS BEEN DEACTIVATED. PLEASE REVIEW THE USER LIST AND SELECT AN ACTIVE USER'
            ),
            this.translate.instant('ACCOUNT DEACTIVATED')
          );
        }

        if (res > 0) {
          this.toastr.success(
            this.translate.instant('A NEW WORK SHIFT WAS ADDED'),
            this.translate.instant('A NEW WORK SHIFT')
          );
          const startTime = dayjs(this.calendarCurrentStart)
            .utcOffset(0, true)
            .format();
          this.getTimelineEvent(true, startTime, oneDay);
          this.GetCalendarWeekList();
        }
      });
  }

  addPlanTemplate(form: any) {
    const TempStarTime = new Date(form.StarTime);
    const TempEndTime = new Date(form.EndTime);

    return this.httpClient.requestCall(
      ApiEndPoints.AddPlanTemplate,
      ApiMethod.POST,
      {
        Name: form.Name,
        StarTime: TempStarTime,
        EndTime: TempEndTime,
      }
    );
  }
  updatePlanTemplateImage(imageId: string, templateId: number): void {
    return this.httpClient
      .requestCall(ApiEndPoints.UpdateTemplateImage, ApiMethod.POST, {
        ImageId: imageId,
        TemplateId: templateId,
      })
      .subscribe((res: number) => {
        if (res) {
        }
      });
  }

  applyPlanTemplate(form: any): void {
    this.httpClient
      .requestCall(ApiEndPoints.ApplyPlanTemplate, ApiMethod.POST, {
        TemplateId: form.Id,
        StarTime: new Date(form.StarTime),
        EndTime: new Date(form.EndTime),
      })
      .subscribe((res: number) => {
        if (res > 0) {
          this.getEvent(true, form.StarTime);
          this.getTimelineEvent(true, form.StarTime);
        }
      });
  }
  deletePlanTemplate(id: number, imageId): void {
    this.httpClient
      .requestCall(`${ApiEndPoints.DeletePlanTemplate}/${id}`, ApiMethod.DELETE)
      .subscribe(async (res: number) => {
        if (res > 0) {
          this.getPlanTemplateList();
          // delete the image by using the imageId from the server using ImageService

          await this.imageService.deleteFile(imageId);
        }
      });
  }

  workShiftListRequest(list: any[]): void {
    this.httpClient
      .requestCall(ApiEndPoints.WorkShiftListRequest, ApiMethod.POST, {
        AcceptedList: list,
      })
      .subscribe((res: number) => {
        if (res > 0) {
          // make this call after 1 second
          setTimeout(() => {
            this.getNotAcceptedEventFromEmployee();
          }, 1000);
          this.getEvent(false, this.calendarCurrentStart);
        }
      });
  }

  getPlanTemplateList(): void {
    this.httpClient
      .requestCall(ApiEndPoints.GetTemplateList, ApiMethod.GET)
      .subscribe((list: any) => {
        this.planTemplateListSource.next(list as any[]);
      });
  }

  updateEvent(
    event: TimeEvent,
    isTimeline = false,
    oneDay = false
  ): HttpService {
    event.StarTime = new Date(event.StarTime);
    if (event.EndTime) {
      event.EndTime = new Date(event.EndTime);
    }

    return this.httpClient
      .requestCall(ApiEndPoints.UpdateEvent, ApiMethod.POST, event)
      .subscribe((res: number) => {
        if (res === -1) {
          this.toastr.error(
            this.translate.instant(
              'THE EMPLOYEE HAS VACATION OR IS SICK DURING THIS PERIOD'
            ),
            this.translate.instant('ENTRY NOT POSSIBLE')
          );
        } else if (res > 0) {
          this.toastr.success(
            this.translate.instant('THE WORK SHIFT WAS UPDATED'),
            this.translate.instant('UPDATED')
          );
        }

        const startTime = dayjs(this.calendarCurrentStart)
          .utcOffset(0, true)
          .format();
        this.getTimelineEvent(true, startTime, oneDay);
        this.GetCalendarWeekList();

        this.departement.getDepartementEmployeeList(
          event.StarTime,
          this.selectedDepartementList
        );
      });
  }

  getEvent(
    withAbsence = false,
    currentWeekStart = null,
    currentWeekEnd = null
  ): Observable<any[]> {
    if (currentWeekStart) {
      this.calendarCurrentStart = currentWeekStart;
    } else if (!currentWeekStart && !this.calendarCurrentStart) {
      currentWeekStart = Date.now();
    } else if (this.calendarCurrentStart) {
      currentWeekStart = this.calendarCurrentStart;
    }

    if (!currentWeekEnd) {
      currentWeekEnd = dayjs(currentWeekStart).add(6, 'days');
    }

    currentWeekStart = dayjs(currentWeekStart)
      .utcOffset(0, true)
      .format('YYYY-MM-DD');
    currentWeekEnd = dayjs(currentWeekEnd)
      .utcOffset(0, true)
      .format('YYYY-MM-DD');
    const departement = this.selectedDepartementList.length
      ? this.selectedDepartementList.join(',')
      : null;
    let endPoint =
      ApiEndPoints.GetEvents +
      '/' +
      withAbsence +
      '/' +
      currentWeekStart +
      '/' +
      departement;
    if (!this.authService.isManegeOrTeamLeader) {
      endPoint =
        ApiEndPoints.GetEmployeeEvents +
        '/' +
        currentWeekStart +
        '/' +
        currentWeekEnd;
    }

    return this.httpClient
      .requestCall(endPoint, ApiMethod.GET)
      .subscribe((list: any) => {
        // check if the list is empty
        if (!list?.length) {
          this.employeeEventListSource.next([]);
          return;
        }
        const clonedList = list
          .filter(
            (x) =>
              x.shiftStatus === WorkShiftStatus.Published ||
              x.shiftStatus === WorkShiftStatus.AcceptedFromEmployee
          )
          .map((x) => ({
            ...x,
            start: new Date(x.start),
            end: new Date(x.end),
            className: 'horizental-line event-animation-scale',
            color: '#ebecfe',
            textColor: '#000',
          }));

        this.employeeEventListSource.next(clonedList);
      });
  }

  getNotAcceptedEventFromEmployee(): Observable<any[]> {
    const currentWeekStart = Date.now();
    const currentWeekEnd = dayjs(currentWeekStart).add(20, 'days').toDate();
    const endPoint =
      ApiEndPoints.GetEmployeeEvents +
      '/' +
      dayjs(currentWeekStart).utcOffset(0, true).format('YYYY-MM-DD') +
      '/' +
      dayjs(currentWeekEnd).utcOffset(0, true).format('YYYY-MM-DD');

    return this.httpClient
      .requestCall(endPoint, ApiMethod.GET)
      .subscribe((list: any[]) => {
        const clonedList = list
          .filter(
            (x) =>
              x.shiftStatus === WorkShiftStatus.Published &&
              new Date(x.start) > new Date()
          )
          .map((x) => ({
            ...x,
            start: new Date(x.start),
            end: new Date(x.end),
          }));

        this.notAcceptedEventListSource.next(clonedList);
      });
  }

  getTimelineEvent(
    withAbsence: boolean = false,
    currentWeekStart = null,
    oneDay = false,
    setCalendarWeek = false,
    currentEmployeesFilled = false,
    searchKeywords = '*',
    withAnimation = true
  ): void {
    searchKeywords = searchKeywords ? searchKeywords : '*';
    if (currentWeekStart) {
      this.calendarCurrentStart = currentWeekStart;
    } else if (!currentWeekStart && !this.calendarCurrentStart) {
      currentWeekStart = Date.now();
    } else if (this.calendarCurrentStart) {
      currentWeekStart = this.calendarCurrentStart;
    }
    currentWeekStart = dayjs(currentWeekStart)
      .utcOffset(0, true)
      .format('YYYY-MM-DD');
    const departement = this.selectedDepartementList.length
      ? this.selectedDepartementList.join(',')
      : null;
    let endPoint =
      ApiEndPoints.GetTimelineEvents +
      '/' +
      withAbsence +
      '/' +
      currentWeekStart +
      '/' +
      departement +
      '/' +
      oneDay +
      '/' +
      currentEmployeesFilled +
      '/' +
      searchKeywords +
      '/' +
      this.currentFilter;
    if (!this.authService.isManegeOrTeamLeader) {
      endPoint = ApiEndPoints.GetTimelineEvents;
    }
    this.calendarCurrentStartFromHorizontalCharts = null;
    this.httpClient
      .requestCall(endPoint, ApiMethod.GET)
      .subscribe((result: any) => {
        result.CalenderEvents = result.CalenderEvents.map((entry) => {
          entry.start = new Date(entry.start);
          entry.end = new Date(entry.end);
          this.setColorAndClassForEvent(entry, withAnimation);
          return entry;
        });

        this.employeeTimelineEventListSource.next({
          calenderEvents: result.CalenderEvents,
          calenderResources: result.CalenderResources,
          calendarWeekStart: setCalendarWeek ? currentWeekStart : null,
        } as any);
        // dont call this fuction 'GetEventsAndScheduleRequirementStatus' if the current week start is in the past
        if (dayjs(currentWeekStart).isSameOrAfter(dayjs(), 'week')) {
          this.GetEventsAndScheduleRequirementStatus(currentWeekStart);
        }
      });
  }

  GetEventsAndScheduleRequirementStatus(currentWeekStart): void {
    currentWeekStart = dayjs(currentWeekStart)
      .utcOffset(0, true)
      .format('YYYY-MM-DD');

    const endPoint =
      ApiEndPoints.GetEventsAndScheduleRequirementStatus +
      '/' +
      currentWeekStart;

    this.httpClient
      .requestCall(endPoint, ApiMethod.GET)
      .subscribe((result: { [key: number]: [] }) => {
        // tslint:disable-next-line: forin
        for (const key in result) {
          const value = result[key];
          value?.forEach((entry: any) => {
            entry.StartTime = new Date(entry.StartTime);
            entry.EndTime = new Date(entry.EndTime);
          });
        }

        this.eventsAndScheduleRequirementStatusListSource.next(result);
      });
  }

  getWorkShiftDetails(eventId): HttpService {
    return this.httpClient
      .requestCall(
        ApiEndPoints.GetWorkShiftDetails + '/' + eventId,
        ApiMethod.GET
      )
      .subscribe((details: any) => {
        this.workShiftDetailsSource.next(details);
      });
  }

  publishWorkPlan(startTime, endTime): HttpService {
    startTime = dayjs(startTime).utcOffset(0, true).format();
    endTime = dayjs(endTime).utcOffset(0, true).format();
    return this.httpClient
      .requestCall(ApiEndPoints.PublishWorkPlan, ApiMethod.POST, {
        StarTime: startTime,
        EndTime: endTime,
      })
      .subscribe((details: any) => {
        this.getEvent(true, startTime);
      });
  }

  publishTimelineWorkPlan(startTime, endTime): HttpService {
    startTime = dayjs(startTime).utcOffset(0, true).format();
    endTime = dayjs(endTime).utcOffset(0, true).format();
    return this.httpClient
      .requestCall(ApiEndPoints.PublishWorkPlan, ApiMethod.POST, {
        StarTime: startTime,
        EndTime: endTime,
      })
      .subscribe((details: any) => {
        this.getTimelineEvent(true, startTime);
        this.GetCalendarWeekList();
      });
  }

  deleteWorkPlan(startTime, endTime): HttpService {
    startTime = new Date(startTime);
    endTime = new Date(endTime);
    return this.httpClient
      .requestCall(ApiEndPoints.DeleteWorkPlan, ApiMethod.POST, {
        StarTime: startTime,
        EndTime: endTime,
      })
      .subscribe((details: any) => {
        this.getEvent(true, startTime);
      });
  }

  deleteTimelineWorkPlan(startTime, endTime): HttpService {
    endTime = dayjs(endTime).format('YYYY-MM-DD');
    return this.httpClient
      .requestCall(ApiEndPoints.DeleteWorkPlan, ApiMethod.POST, {
        StarTime: startTime,
        EndTime: endTime,
      })
      .subscribe((details: any) => {
        this.getTimelineEvent(true, startTime);
        this.GetCalendarWeekList();
      });
  }

  deleteEvent(eventId, startTime): HttpService {
    startTime = dayjs(startTime).format('YYYY-MM-DD');
    return this.httpClient
      .requestCall(ApiEndPoints.DeleteEvent, ApiMethod.POST, {
        EventId: eventId,
      })
      .subscribe((details: any) => {
        this.getEvent(true, startTime);
      });
  }
  deleteEventTimeline(eventId, startTime, oneDay = false): HttpService {
    startTime = dayjs(startTime).format('YYYY-MM-DD');
    return this.httpClient
      .requestCall(ApiEndPoints.DeleteEvent, ApiMethod.POST, {
        EventId: eventId,
      })
      .subscribe((details: any) => {
        this.getTimelineEvent(true, startTime, oneDay);
        this.GetCalendarWeekList();
      });
  }

  deleteOpenShift(eventId, startTime = null): HttpService {
    startTime = dayjs(startTime).format('YYYY-MM-DD');
    return this.httpClient
      .requestCall(ApiEndPoints.DeleteOpenShift, ApiMethod.POST, {
        EventId: eventId,
      })
      .subscribe((details: any) => {
        this.getTimelineEvent(true, startTime);
        this.GetCalendarWeekList();
      });
  }

  GetCalendarWeekList(): HttpService {
    return this.httpClient
      .requestCall(ApiEndPoints.GetCalendarWeekList, ApiMethod.GET)
      .subscribe((details: CalendarWeek[]) => {
        this.calenderWeekListSource.next(details);
      });
  }

  setCurrentCalenderWeek(StartTime, isHorizontal): void {
    this.calendarCurrentStart = StartTime;
    // get the current week number from the start date
    const currentWeekNumber = dayjs(StartTime).week();

    const dayOftheWeek =
      this.companySettings.CompanySettings.CalendarOptions.FirstDay;
    // get the date based on the current week number and the day of the week (0 = Sunday)
    const currentWeekStart = dayjs()
      .week(currentWeekNumber)
      .day(dayOftheWeek)
      .format('YYYY-MM-DD');

    if (!isHorizontal) {
      this.getTimelineEvent(true, currentWeekStart, false, true);
    } else {
      this.calendarCurrentStartFromHorizontalCharts = currentWeekStart;
    }
  }

  GetEmployeeAvailability(employeeId: number, startTime: any) {
    return this.httpClient.requestCall(
      ApiEndPoints.GetEmployeeAvailability +
        '/' +
        employeeId +
        '/' +
        dayjs(startTime).utcOffset(0, true).format('YYYY-MM-DD'),
      ApiMethod.GET
    );
  }

  setColorAndClassForEvent(event: any, withAnimation: boolean): void {
    const animationScale = withAnimation ? 'event-animation-scale' : '';
    event.className =
      event.shiftStatus === WorkShiftStatus.RejectedFromEmployee
        ? 'vertical-line-rejected ' + animationScale
        : event.shiftStatus === WorkShiftStatus.AcceptedFromEmployee
        ? 'vertical-line-accepted ' + animationScale
        : event.shiftStatus === WorkShiftStatus.Draft
        ? 'stripes vertical-line-draft ' + animationScale
        : 'vertical-line-draft ' + animationScale;

    event.className =
      event.eventType === EventType.OpenShift
        ? 'vertical-line-open-shift ' + animationScale
        : event.className;
    event.color =
      event.shiftStatus === WorkShiftStatus.RejectedFromEmployee
        ? '#88270920'
        : event.shiftStatus === WorkShiftStatus.AcceptedFromEmployee
        ? '#00695c20'
        : '#ebecfe';

    event.color =
      event.eventType === EventType.OpenShift ? '#37474F65' : event.color;

    event.textColor = '#000000';
  }

  callAutomaticFill(
    dayNumber: number,
    currentWeekStart: Date,
    fillWeekDays: boolean = false
  ): HttpService {
    // add one second to the current week start date
    currentWeekStart = dayjs(currentWeekStart).add(1, 'm').toDate();
    return this.httpClient
      .requestCall(ApiEndPoints.FillWorkShiftsAutomatically, ApiMethod.POST, {
        DayNumber: dayNumber,
        CurrentWeekStart: new Date(currentWeekStart),
        FillWeekDays: fillWeekDays,
      })
      .subscribe((details: any) => {
        this.getTimelineEvent(true, currentWeekStart);
        this.GetCalendarWeekList();
      });
  }

  refreshForEmployeeDashboard() {}

  shiftStatusMapping(shiftStatus: WorkShiftStatus): string {
    switch (shiftStatus) {
      case WorkShiftStatus.RejectedFromEmployee:
        return 'bi bi-x-circle-fill';
      case WorkShiftStatus.SwapRequested:
        return 'bi bi-arrow-left-right';
      case WorkShiftStatus.AcceptedFromEmployee:
        return 'bi bi-check2-all request-resived-icon';
      case WorkShiftStatus.Draft:
        return '';
      default:
        return 'bi bi-check2';
    }
  }
}
