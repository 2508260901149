import { CalendarSettings } from './calendar-settings.model';
import { ShiftSwapSettings } from './shift-swap-settings.model';
import { TimeTrackingSettings } from './time-tracking-settings.model';
import { WorkingHoursAct } from './working-hours-act.model';

export class CompanySettings {
  CompanyName: string;
  TimeZone: string = 'Europe/Berlin';
  CalendarOptions: CalendarSettings;
  ShiftSwapSettings: ShiftSwapSettings;
  TimeTrackingSettings: TimeTrackingSettings;
  WorkStructure: number = WorkStructure.Departments;

  constructor() {
    this.CalendarOptions = new CalendarSettings();
    this.ShiftSwapSettings = new ShiftSwapSettings();
    this.TimeTrackingSettings = new TimeTrackingSettings();
  }

  static fromStorage(data: CompanySettings): CompanySettings {
    const companySettings = new CompanySettings();

    if (data && data.CompanyName) {
      companySettings.CompanyName = data.CompanyName;
      companySettings.TimeZone = data.TimeZone;
      companySettings.WorkStructure =
        data.WorkStructure || WorkStructure.Departments;

      // Map CalendarOptions if present in data
      if (data.CalendarOptions) {
        companySettings.CalendarOptions = {
          FirstDay: data.CalendarOptions.FirstDay || 1,
          Weekends: data.CalendarOptions.Weekends || false,
          BusinessHoursStartTime:
            data.CalendarOptions.BusinessHoursStartTime || '08:00',
          BusinessHoursEndTime:
            data.CalendarOptions.BusinessHoursEndTime || '23:00',
        };
      }

      // Map ShiftSwapSettings if present in data
      if (data.ShiftSwapSettings) {
        companySettings.ShiftSwapSettings = {
          DaysInAdvance: data.ShiftSwapSettings.DaysInAdvance || 5,
          AllowShiftSwap: data.ShiftSwapSettings.AllowShiftSwap || false,
        };
      }

      // Map TimeTrackingSettings if present in data
      if (data.TimeTrackingSettings) {
        companySettings.TimeTrackingSettings = {
          AutoStopTimeOnMaxHours:
            data.TimeTrackingSettings.AutoStopTimeOnMaxHours || false,
          MaxDailyWorkingHours:
            data.TimeTrackingSettings.MaxDailyWorkingHours || 10,
          MaxWeeklyWorkingHours:
            data.TimeTrackingSettings.MaxWeeklyWorkingHours || 48,
          MinDailyWorkingHours:
            data.TimeTrackingSettings.MinDailyWorkingHours || 8, // Assuming default value
          MinWeeklyWorkingHours:
            data.TimeTrackingSettings.MinWeeklyWorkingHours || 40, // Assuming default value
          WorkTimeWindow: data.TimeTrackingSettings.WorkTimeWindow, // Assuming default value
          MaxConsecutiveWorkDays:
            data.TimeTrackingSettings.MaxConsecutiveWorkDays || 5, // Assuming default value
          // Add other missing properties with assumed default values or logic to extract them from `data`
          BreakTime: data.TimeTrackingSettings.BreakTime || 30,
          LongBreakTime: data.TimeTrackingSettings.LongBreakTime || 45,
          AfterHours: data.TimeTrackingSettings.AfterHours || 2,
          RestPeriods: {
            DailyRestPeriod:
              data.TimeTrackingSettings.RestPeriods.DailyRestPeriod || 11,
            WeeklyRestPeriod:
              data.TimeTrackingSettings.RestPeriods.WeeklyRestPeriod || 24,
            HolidayRestPeriod:
              data.TimeTrackingSettings.RestPeriods.HolidayRestPeriod || 24,
          },
          Flextime: {
            IsFlextime: data.TimeTrackingSettings.Flextime.IsFlextime || false,
            CoreTimeStart:
              data.TimeTrackingSettings.Flextime.CoreTimeStart || '09:00',
            CoreTimeEnd:
              data.TimeTrackingSettings.Flextime.CoreTimeEnd || '15:00',
          },
          Overtime: {
            IsOvertime:
              data.TimeTrackingSettings?.Overtime?.IsOvertime || false,
            MaxOvertimeHours:
              data.TimeTrackingSettings?.Overtime?.MaxOvertimeHours || 10,
          },
          Notifications: {
            Enable: data.TimeTrackingSettings?.Notifications?.Enable || false,
            Email: data.TimeTrackingSettings?.Notifications?.Email || false,
          },
          MobileTimeTracking: {
            Enable:
              data.TimeTrackingSettings?.MobileTimeTracking?.Enable || false,
            GPSTracking:
              data.TimeTrackingSettings?.MobileTimeTracking?.GPSTracking ||
              false,
          },
          EmployeeSettings: {
            CanAddTimeRecord:
              data.TimeTrackingSettings?.EmployeeSettings?.CanAddTimeRecord ||
              false,
            CanEditTimeRecord:
              data.TimeTrackingSettings?.EmployeeSettings?.CanEditTimeRecord ||
              false,
            CanDeleteTimeRecord:
              data.TimeTrackingSettings?.EmployeeSettings
                ?.CanDeleteTimeRecord || false,
          },
        };
      }
    }

    return companySettings;
  }
}

export enum WorkStructure {
  Departments = 1,
  Projects = 2,
  Both = 3,
}
