import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {
  CompanySettings,
  WorkStructure,
} from '../../model/company-settings.model';
import { CompanySettingsService } from '../../services/company-settings.service';

@Component({
  selector: 'app-company-settings',
  templateUrl: './company-settings.component.html',
  styleUrls: ['./company-settings.component.css'],
})
export class CompanySettingsComponent implements OnInit {
  companySettings: CompanySettings = new CompanySettings();
  WorkStructure = WorkStructure;

  // generate a list of days of the week for the dropdown list key is the number day of the week
  daysOfWeek = [
    { key: 0, value: this.translate.instant('SUNDAY') },
    { key: 1, value: this.translate.instant('MONDAY') },
    { key: 2, value: this.translate.instant('TUESDAY') },
    { key: 3, value: this.translate.instant('WEDNESDAY') },
    { key: 4, value: this.translate.instant('THURSDAY') },
    { key: 5, value: this.translate.instant('FRIDAY') },
    { key: 6, value: this.translate.instant('SATURDAY') },
  ];

  companyWorkStructure = [
    {
      key: WorkStructure.Departments,
      value: this.translate.instant('DEPARTMENTS'),
    },
    { key: WorkStructure.Projects, value: this.translate.instant('PROJECTS') },
    { key: WorkStructure.Both, value: this.translate.instant('HYBRID') },
  ];

  // Liste der Zeitzonen für die Dropdown-Auswahl
  timeZones = [
    { key: 'Africa/Cairo', value: 'Africa/Cairo' },
    { key: 'Africa/Johannesburg', value: 'Africa/Johannesburg' },
    { key: 'Africa/Nairobi', value: 'Africa/Nairobi' },
    { key: 'America/Chicago', value: 'America/Chicago' },
    { key: 'America/Denver', value: 'America/Denver' },
    { key: 'America/Los_Angeles', value: 'America/Los_Angeles' },
    { key: 'America/Sao_Paulo', value: 'America/Sao_Paulo' },
    { key: 'America/Mexico_City', value: 'America/Mexico_City' },
    {
      key: 'America/Argentina/Buenos_Aires',
      value: 'America/Argentina/Buenos_Aires',
    },
    { key: 'Asia/Dubai', value: 'Asia/Dubai' },
    { key: 'Asia/Hong_Kong', value: 'Asia/Hong_Kong' },
    { key: 'Asia/Kolkata', value: 'Asia/Kolkata' },
    { key: 'Asia/Shanghai', value: 'Asia/Shanghai' },
    { key: 'Asia/Tokyo', value: 'Asia/Tokyo' },
    { key: 'Asia/Singapore', value: 'Asia/Singapore' },
    { key: 'Asia/Bangkok', value: 'Asia/Bangkok' },
    { key: 'Australia/Sydney', value: 'Australia/Sydney' },
    { key: 'Australia/Melbourne', value: 'Australia/Melbourne' },
    { key: 'Australia/Brisbane', value: 'Australia/Brisbane' },
    { key: 'Europe/Berlin', value: 'Europe/Berlin' },
    { key: 'Europe/Paris', value: 'Europe/Paris' },
    { key: 'Europe/London', value: 'Europe/London' },
    { key: 'Europe/Moscow', value: 'Europe/Moscow' },
    { key: 'Europe/Rome', value: 'Europe/Rome' },
    { key: 'Europe/Madrid', value: 'Europe/Madrid' },
    { key: 'Europe/Istanbul', value: 'Europe/Istanbul' },
    { key: 'Europe/Kiev', value: 'Europe/Kiev' },
    { key: 'Pacific/Auckland', value: 'Pacific/Auckland' },
    { key: 'Pacific/Honolulu', value: 'Pacific/Honolulu' },
    { key: 'Etc/UTC', value: 'Etc/UTC' },
    { key: 'Etc/GMT', value: 'Etc/GMT' },
    { key: 'Etc/GMT+1', value: 'Etc/GMT+1' },
    { key: 'Etc/GMT-1', value: 'Etc/GMT-1' },
  ];

  selectedTime: string = '';
  suggestions = ['10', '15', '20', '25', '30', '40', '45', '50', '60'];

  constructor(
    private companySetting: CompanySettingsService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.companySetting.CompanySettingsObservable.subscribe((settings) => {
      if (settings) {
        this.companySettings = settings;
      }
    });
    this.companySetting.getCompanySettings();
  }

  onSubmit(form: NgForm): void {}

  onUpdate(value): void {
    this.companySetting.updateCompanySettings(this.companySettings);
  }
}
