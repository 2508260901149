import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  Optional,
} from '@angular/core';
import {
  NgForm,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { JobType, UserRoles } from 'src/app/core/services/const';
import { AuthService } from 'src/app/modules/auth/services/auth/auth.service';
import { Departement } from 'src/app/modules/departement/model/departement';
import { DepartementService } from 'src/app/modules/departement/services/departement.service';
import { Skill } from 'src/app/modules/skill/model/departement';
import { SkillService } from 'src/app/modules/skill/services/skill.service';
import { EmployeeService } from '../../../employee/services/employee.service';
import { Employee } from '../../model/employee.model';
import { WorkStructure } from 'src/app/modules/settings/model/company-settings.model';
import { CompanySettingsService } from 'src/app/modules/settings/services/company-settings.service';
import { Department } from 'src/app/modules/admin/model/department.model';
import { DepartmentType } from 'src/app/modules/departement/model/add-departement';

@Component({
  selector: 'app-add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.css'],
})
export class AddEmployeeComponent implements OnInit, OnDestroy {
  @Input() formModel: Employee = {
    Id: 0,
    FirstName: '',
    LastName: '',
    Gender: 0,
    CivilStatus: 0,
    MobileNo: '',
    HomePhoneNo: '',
    Street1: '',
    City: '',
    ZipCode: '',
    ProfileImage: '',
    DepartmentList: [],
    Department: null,
    WorkingTime: 0,
    Role: 2,
    Email: '',
    Holidays: 0,
    SkillList: [],
    IsActive: true,
    EmailStatus: 0,
    WorkMinutesPerDay: 0,
    WorkDaysPerWeek: 5,
    JobType: JobType.FullTime,
  };
  @Input() showButtons: boolean = true; // Steuerung der internen Buttons
  @Output() onSave = new EventEmitter<Employee>(); // Ereignis zum Emittieren der gespeicherten Daten

  @ViewChild('addEmployeeForm') form: NgForm; // Zugriff auf das Formular

  userRoles = UserRoles;
  userRolesList: string[] = [];

  employeeHaseEmail = false;

  minEndTime = dayjs().startOf('day').set('h', 8).format('HH:mm');
  maxEndTime = dayjs().startOf('day').set('h', 10).format('HH:mm');

  hour = {
    hoursPerDay: '08:00',
  };

  jobTypes = [
    { id: JobType.FullTime, label: this.translate.instant('FULL-TIME') },
    { id: JobType.PartTime, label: this.translate.instant('PART-TIME') },
    { id: JobType.MiniJob, label: this.translate.instant('MINI-JOB') },
  ];
  structure: number = WorkStructure.Departments;
  workStructureEnum = WorkStructure;

  private subscriptionDepartmentList: Subscription;
  private subscriptionSkillList: Subscription;

  departmentList: Departement[] = [];
  JobType = JobType;
  skillList: Skill[] = [];

  userRole = [
    { id: 2, label: this.translate.instant('Employee') },
    { id: 3, label: this.translate.instant('TEAM LEADER') },
  ];

  departmentValue = new UntypedFormControl();
  skillValue = new UntypedFormControl();
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    @Optional() public dialogRef: MatDialogRef<AddEmployeeComponent>,
    public service: EmployeeService,
    public departementService: DepartementService,
    public skillService: SkillService,
    private translate: TranslateService,
    public authService: AuthService,
    public companySettingsService: CompanySettingsService
  ) {
    this.userRolesList = Object.keys(this.userRoles).filter(
      (f) => !isNaN(Number(f))
    );
    this.structure = this.companySettingsService.CompanySettings.WorkStructure;
  }

  ngOnInit(): void {
    if (this.data && this.data.employee && this.data.employee.Id) {
      this.formModel = {
        ...this.data.employee,
        JobType: this.data.employee.JobType || JobType.Nothing,
      };

      // Überprüfen, ob der Mitarbeiter eine gültige E-Mail-Adresse hat
      this.employeeHaseEmail = this.data.employee?.Email?.match(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
      )
        ? true
        : false;

      this.hour.hoursPerDay = dayjs
        .utc()
        .startOf('day')
        .add(this.data.employee.WorkMinutesPerDay, 'minutes')
        .format('HH:mm');
    }

    // Abonnieren der Department-Liste
    this.subscriptionDepartmentList =
      this.departementService.departementList.subscribe((list) => {
        this.departmentList = list as Departement[];
      });
    this.departementService.getDepartementList();

    // Abonnieren der Skill-Liste
    this.subscriptionSkillList = this.skillService.AllSkillList.subscribe(
      (list) => {
        this.skillList = list as Skill[];
      }
    );
    this.skillService.getAllSkillList();
  }

  onSubmit(form: NgForm): void {
    if (form.valid) {
      // Zerlege die Stunden und Minuten
      const [hours, minutes] = this.hour.hoursPerDay.split(':').map(Number);

      if (!isNaN(hours) && !isNaN(minutes)) {
        // Konvertiere Stunden und Minuten in Gesamtminuten
        this.formModel.WorkMinutesPerDay = dayjs
          .duration({ hours, minutes })
          .asMinutes();

        if (this.formModel.JobType !== JobType.MiniJob) {
          const workInMinutes =
            this.formModel.WorkMinutesPerDay * this.formModel.WorkDaysPerWeek;
          // Konvertiere in Stunden mit 2 Dezimalstellen
          this.formModel.WorkingTime = +(workInMinutes / 60).toFixed(2);
        }

        // Füge den Arbeitgeber hinzu oder aktualisiere ihn
        if (this.formModel.Id === 0) {
          this.service.addEmployer(this.formModel).subscribe({
            next: () => {
              if (this.dialogRef) {
                // Wenn als Dialog verwendet, schließen und Daten zurückgeben
                this.dialogRef.close(this.formModel);
              } else {
                // Wenn eingebettet, das onSave Ereignis emittieren
                this.onSave.emit(this.formModel);
              }
            },
            error: (error) => {
              console.error('Fehler beim Hinzufügen des Mitarbeiters', error);
              // Optional: Fehlerbehandlung hinzufügen
            },
          });
        } else {
          this.service.addEmployer(this.formModel).subscribe({
            next: () => {
              this.onSave.emit(this.formModel);
              this.onClose();
            },
            error: (error) => {
              console.error(
                'Fehler beim Aktualisieren des Mitarbeiters',
                error
              );
              // Optional: Fehlerbehandlung hinzufügen
            },
          });
        }
      }
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    if (this.subscriptionDepartmentList) {
      this.subscriptionDepartmentList.unsubscribe();
    }
    if (this.subscriptionSkillList) {
      this.subscriptionSkillList.unsubscribe();
    }
  }

  // Methode zum Speichern, aufrufbar von der Elternkomponente
  save(): void {
    this.onSubmit(this.form);
  }

  // Getter zur Überprüfung der Formvalidität
  get isValid(): boolean {
    return this.form?.valid;
  }
}
